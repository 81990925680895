<template>
	<div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'

export default {
	mixins: [resize],
	props: {
		xData: {
			type: Array,
			default: function () {
				return []
			}
		},
		sData: {
			type: Array,
			default: function () {
				return []
			}
		},
        maxAxis: {
            type: String,
            default: '4'
        },
		className: {
			type: String,
			default: 'chart'
		},
		id: {
			type: String,
			default: 'chart'
		},
		width: {
			type: String,
			default: '200px'
		},
		height: {
			type: String,
			default: '200px'
		}
	},
	data() {
		return {
			chart: null
		}
	},
	mounted() {
		this.initChart()
	},
	beforeDestroy() {
		if (!this.chart) {
			return
		}
		this.chart.dispose()
		this.chart = null
	},
	methods: {
		initChart() {
			this.chart = echarts.init(document.getElementById(this.id))

			this.chart.setOption({
                animation: false,
                grid: {
                    left: '0',
                    right: '0',
                    top: '1px',
                    bottom: '1px'
                },
				xAxis: {
					type: 'category',
					data: this.xData,
                    axisTick: {
                        show: false
                    },
					axisLabel: {
						show: false
					},
					boundaryGap: false
				},
				yAxis: {
                    max: this.maxAxis * 1,
					type: 'value',
                    splitLine: {
                        show: false
                    },
					axisLabel: {
						show: false
					},
                    minInterval: this.maxAxis / 4
				},
				series: this.sData

			})
		}
	}
}
</script>
