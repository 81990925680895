<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main" v-if="dateType==1">{{$t('sycm.common.statisticalTime')}} {{ parseInt(Date.now()/1000) | parseTime()}}</div>
			<div class="filter-main" v-else-if="[2,5].includes(dateType)">{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<div class="filter-main" v-else>{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}~{{ parseInt(endTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 1}" @click="handleChangeTime(1)">{{$t('sycm.actions.realTime')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 2}" @click="handleChangeTime(2)">1{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 3}" @click="handleChangeTime(3)">7{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 4}" @click="handleChangeTime(4)">30{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 5}" @click="handleDatePicker(5)">{{$t('sycm.actions.day')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="dayData"
								type="date"
								ref="dayPicker"
								:placeholder="$t('sycm.placeholder.date')"
								:picker-options="pickerOptions"
								@change="changeDatePicker($event,5)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 6}" @click="handleDatePicker(6)">{{$t('sycm.actions.week')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="weekData"
								type="week"
								ref="weekPicker"
								format="WW"
								:placeholder="$t('sycm.placeholder.week')"
								:picker-options="pickerWeekOptions"
								@change="changeDatePicker($event,6)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 7}" @click="handleDatePicker(7)">{{$t('sycm.actions.month')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="monthData"
								type="month"
								ref="monthPicker"
								:placeholder="$t('sycm.placeholder.month')"
								:picker-options="pickerMonthOptions"
								@change="changeDatePicker($event,7)">
							</el-date-picker>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.flow.overview')}}</div>
				<div class="barbox">
					<el-dropdown class="sycm-bar-dropdown" placement="bottom" @command="handleCommand">
						<span class="el-dropdown-link">
							{{ terminalType | terminalFilter($t('sycm.dropdown.terminal')) }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="(item,index) in $t('sycm.dropdown.terminal')" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div class="section-main">
				<div class="sycm-panel">
					<div class="item active">
						<div class="item-main">
							<div class="name">{{$t('sycm.flow.visitorStore')}}</div>
							<ul class="item-box">
								<li><div class="li-label">{{$t('sycm.common.visitor')}}</div><div class="li-block"><span class="number">{{storeData.curr_value}}</span></div></li>
								<li>
									<div class="li-label">{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</div>
									<div class="li-block" v-if="storeData.rate > 0" :class="storeData.curr_value > storeData.comp_value ? 'red' : 'green'">
										<span>{{storeData.rate}}%</span>
										<i :class="storeData.curr_value > storeData.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i>
									</div>
									<div class="li-block" v-else>-</div>
								</li>
								<li><div class="li-lable"></div><div class="li-block">-</div></li>
							</ul>
						</div>
						<div class="item-chart">
							<flow-chart id="storeChart" v-if="storeChart" :x-data="storeChart.xData" :s-data="storeChart.sData" :max-axis="storeChart.max.toString()" height="100%" width="100%" />
						</div>
					</div>
					<div class="item">
						<div class="item-main">
							<div class="name">{{$t('sycm.flow.visitorGoods')}}</div>
							<ul class="item-box">
								<li><div class="li-label">{{$t('sycm.flow.goodsVisitors')}}</div><div class="li-block"><span class="number">{{goodsData.curr_value}}</span></div></li>
								<li>
									<div class="li-label">{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</div>
									<div class="li-block" v-if="goodsData.rate > 0" :class="goodsData.curr_value > goodsData.comp_value ? 'red' : 'green'">
										<span>{{goodsData.rate}}%</span>
										<i :class="goodsData.curr_value > goodsData.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i>
									</div>
									<div class="li-block" v-else>-</div>
								</li>
								<li><div class="li-lable"></div><div class="li-block">-</div></li>
							</ul>
						</div>
						<div class="item-chart">
							<flow-chart id="goodsChart" v-if="goodsChart" :x-data="goodsChart.xData" :s-data="goodsChart.sData" :max-axis="goodsChart.max.toString()" height="100%" width="100%" />
						</div>
					</div>
					<div class="item">
						<div class="item-main">
							<div class="name">{{$t('sycm.flow.conversion')}}</div>
							<ul class="item-box">
								<li><div class="li-label">{{$t('sycm.common.payBuyers')}}</div><div class="li-block"><span class="number">{{buyersData.curr_value}}</span></div></li>
								<li>
									<div class="li-label">{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</div>
									<div class="li-block" v-if="buyersData.rate > 0" :class="buyersData.curr_value > buyersData.comp_value ? 'red' : 'green'">
										<span>{{buyersData.rate}}%</span>
										<i :class="buyersData.curr_value > buyersData.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i>
									</div>
									<div class="li-block" v-else>-</div>
								</li>
								<li><div class="li-lable"></div><div class="li-block">-</div></li>
							</ul>
						</div>
						<div class="item-chart">
							<flow-chart id="buyersChart" v-if="buyersChart" :x-data="buyersChart.xData" :s-data="buyersChart.sData" :max-axis="buyersChart.max.toString()" height="100%" width="100%" />
						</div>
					</div>
				</div>
				<div class="sycm-multiple">
					<div class="item" v-if="overviewData.visitors" :class="{active:chartType==overviewData.visitors.type}" @click="getFlowChart(overviewData.visitors.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.common.visitor')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover"
									popper-class="sycm-popover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-head">
										<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.visitor')+'”'}}</p>
										<p>{{$t('sycm.popover.visitor1')}}</p>
									</div>
									<div class="popover-main">
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.range')}}</div>
											<div class="item-block">{{$t('sycm.popover.visitor2')}}</div>
										</div>
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.repeat')}}</div>
											<div class="item-block">{{$t('sycm.popover.visitor3')}}</div>
										</div>
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.wave')}}</div>
											<div class="item-block">{{$t('sycm.popover.visitor4')}}</div>
										</div>
									</div>
								</el-popover>
							</div>
							<div class="number">{{ overviewData.visitors.curr_value }}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.visitors.rate > 0" :class="overviewData.visitors.curr_value > overviewData.visitors.comp_value ? 'red' : 'green'">{{overviewData.visitors.rate}}% <i :class="overviewData.visitors.curr_value > overviewData.visitors.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.views" :class="{active:chartType==overviewData.views.type}" @click="getFlowChart(overviewData.views.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.common.views')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover"
									popper-class="sycm-popover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-head">
										<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.views')+'”'}}</p>
										<p>{{$t('sycm.popover.views1')}}</p>
									</div>
									<div class="popover-main">
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.range')}}</div>
											<div class="item-block">{{$t('sycm.popover.views2')}}</div>
										</div>
										<div class="item">
											<div class="item-label">{{$t('sycm.popover.repeat')}}</div>
											<div class="item-block">{{$t('sycm.popover.views3')}}</div>
										</div>
									</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.views.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.views.rate > 0" :class="overviewData.views.curr_value > overviewData.views.comp_value ? 'red' : 'green'">{{overviewData.views.rate}}% <i :class="overviewData.views.curr_value > overviewData.views.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.avg_views" :class="{active:chartType==overviewData.avg_views.type}" @click="getFlowChart(overviewData.avg_views.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.perCapitaViews')}}</span>
							</div>
							<div class="number">{{overviewData.avg_views.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.avg_views.rate > 0" :class="overviewData.avg_views.curr_value > overviewData.avg_views.comp_value ? 'red' : 'green'">{{overviewData.avg_views.rate}}% <i :class="overviewData.avg_views.curr_value > overviewData.avg_views.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.old_visitors" :class="{active:chartType==overviewData.old_visitors.type}" @click="getFlowChart(overviewData.old_visitors.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.oldVisitors')}}</span>
							</div>
							<div class="number">{{overviewData.old_visitors.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.old_visitors.rate > 0" :class="overviewData.old_visitors.curr_value > overviewData.old_visitors.comp_value ? 'red' : 'green'">{{overviewData.old_visitors.rate}}% <i :class="overviewData.old_visitors.curr_value > overviewData.old_visitors.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.new_visitors" :class="{active:chartType==overviewData.new_visitors.type}" @click="getFlowChart(overviewData.new_visitors.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.newVisitors')}}</span>
							</div>
							<div class="number">{{overviewData.new_visitors.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.new_visitors.rate > 0" :class="overviewData.new_visitors.curr_value > overviewData.new_visitors.comp_value ? 'red' : 'green'">{{overviewData.new_visitors.rate}}% <i :class="overviewData.new_visitors.curr_value > overviewData.new_visitors.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.store_follows" :class="{active:chartType==overviewData.store_follows.type}" @click="getFlowChart(overviewData.store_follows.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.followStores')}}</span>
							</div>
							<div class="number">{{overviewData.store_follows.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.store_follows.rate > 0" :class="overviewData.store_follows.curr_value > overviewData.store_follows.comp_value ? 'red' : 'green'">{{overviewData.store_follows.rate}}% <i :class="overviewData.store_follows.curr_value > overviewData.store_follows.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.live_visitors" :class="{active:chartType==overviewData.live_visitors.type}" @click="getFlowChart(overviewData.live_visitors.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.liveVisitors')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-text">{{$t('sycm.popover.liveVisitors2')}}</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.live_visitors.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.live_visitors.rate > 0" :class="overviewData.live_visitors.curr_value > overviewData.live_visitors.comp_value ? 'red' : 'green'">{{overviewData.live_visitors.rate}}% <i :class="overviewData.live_visitors.curr_value > overviewData.live_visitors.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.short_videos_visitors" :class="{active:chartType==overviewData.short_videos_visitors.type}" @click="getFlowChart(overviewData.short_videos_visitors.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.videoVisitors')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-text">{{$t('sycm.popover.videoVisitors1')}}</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.short_videos_visitors.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.short_videos_visitors.rate > 0" :class="overviewData.short_videos_visitors.curr_value > overviewData.short_videos_visitors.comp_value ? 'red' : 'green'">{{overviewData.short_videos_visitors.rate}}% <i :class="overviewData.short_videos_visitors.curr_value > overviewData.short_videos_visitors.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.graphic_visitors" :class="{active:chartType==overviewData.graphic_visitors.type}" @click="getFlowChart(overviewData.graphic_visitors.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.graphicVisitors')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-text">{{$t('sycm.popover.graphicVisitors1')}}</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.graphic_visitors.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.graphic_visitors.rate > 0" :class="overviewData.graphic_visitors.curr_value > overviewData.graphic_visitors.comp_value ? 'red' : 'green'">{{overviewData.graphic_visitors.rate}}% <i :class="overviewData.graphic_visitors.curr_value > overviewData.graphic_visitors.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
					<div class="item" v-if="overviewData.store_page_visitors" :class="{active:chartType==overviewData.store_page_visitors.type}" @click="getFlowChart(overviewData.store_page_visitors.type)">
						<div class="item-main">
							<div class="name">
								<span>{{$t('sycm.flow.storePageVisitors')}}</span>
								<el-popover
									placement="bottom-start"
									width="477"
									trigger="hover">
									<i slot="reference" class="el-icon-question"></i>
									<div class="popover-text">{{$t('sycm.popover.storePageVisitors1')}}</div>
								</el-popover>
							</div>
							<div class="number">{{overviewData.store_page_visitors.curr_value}}</div>
						</div>
						<div class="item-box">
							<span>{{dateType | typeFilter($t('sycm.options.flowEarlier'))}}</span>
							<span v-if="overviewData.store_page_visitors.rate > 0" :class="overviewData.store_page_visitors.curr_value > overviewData.store_page_visitors.comp_value ? 'red' : 'green'">{{overviewData.store_page_visitors.rate}}% <i :class="overviewData.store_page_visitors.curr_value > overviewData.store_page_visitors.comp_value ? 'el-icon-top' : 'el-icon-bottom'"></i></span>
							<span v-else>-</span>
						</div>
					</div>
				</div>
				<div class="sycm-chart">
					<sycm-chart id="chartOption" v-if="chartOption" :x-data="chartOption.xData" :s-data="chartOption.sData" :max-axis="chartOption.max.toString()" :x-interval="xInterval" legend-left="0" height="100%" width="100%" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import flowChart from '@/components/echart/flowChart'
import sycmChart from '@/components/echart/ipollChart'

import { fetchFlowVisitStore, fetchFlowVisitGoods, fetchFlowVisitBuyers, fetchFlowVisitOverview, fetchFlowVisitChart } from "@/api/sycm"

export default {
	components: { flowChart, sycmChart },
	filters: {
		terminalFilter(type, options){
			if(type=='') {
				type = 0
			}
			return options[type].label
		}
	},
	data() {
		return {
			terminalType: '',
			dateType: 1,
			dayData: '',
			weekData: '',
			monthData: '',
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			pickerWeekOptions: {
				disabledDate(time) {
					const today = new Date()
					const day = today.getDay()
					today.setDate(today.getDate()-day)
					const end_time = today.getTime()
					today.setDate(today.getDate()-84)
					const start_time = today.getTime()
					return time.getTime() >end_time || time.getTime() < start_time
				},
				firstDayOfWeek: 1
			},
			pickerMonthOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-1)
					const end_time = today.getTime()
					today.setMonth(today.getMonth()-11)
					const start_time = today.getTime()
					return time.getTime() > end_time || time.getTime() < start_time
				}
			},
			startTime: '',
			endTime: '',
			storeData: {},
			storeChart: null,
			goodsData: {},
			goodsChart: null,
			buyersData: {},
			buyersChart: null,
			overviewData: {},
			chartType: 1,
			chartOption: null,
			xInterval: 1
		}
	},
	created () {
		const today = new Date()
		this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
		this.endTime = Date.now()
		this.getData()
	},
	methods: {
		async getData() {
			const loading = this.$loading()
			this.storeChart = null
			this.goodsChart = null
			this.buyersChart = null
			try {
				const params = {
					source: this.terminalType,
					time_from: parseInt(this.startTime / 1000),
					time_to: parseInt(this.endTime / 1000),
					date_type: this.dateType
				}
				const store = await fetchFlowVisitStore(params)
				this.storeData = store.data
				this.storeChart = this.getChartData(store.data.list || [])

				const goods = await fetchFlowVisitGoods(params)
				this.goodsData = goods.data
				this.goodsChart = this.getChartData(goods.data.list || [])

				const buyers = await fetchFlowVisitBuyers(params)
				this.buyersData = buyers.data
				this.buyersChart = this.getChartData(buyers.data.list || [])

				const overview = await fetchFlowVisitOverview(params)
				this.overviewData = overview.data
				this.getFlowChart()
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		async getFlowChart(type) {
			this.chartType = type || this.chartType
			this.chartOption = null
			const params = {
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000),
				date_type: this.dateType,
				type: this.chartType
			}
			const { data } = await fetchFlowVisitChart(params)
			
			const xData = []
			const todayData = []
			const yesterdayData = []
			const d = new Date()
			const hours = d.getHours()
			var max = 0
			data.list.forEach(item=> {
				if(item.x_values && item.x_values.indexOf('-') < 0 && this.dateType !== 6) {
					xData.push(item.x_values+":00")
					if( hours >= parseInt(item.x_values)) {
						todayData.push(item.values)
					}
				} else {
					xData.push(item.x_values)
					todayData.push(item.values)
				}
				yesterdayData.push(item.comp_values)
				if(item.values > max) {
					max = item.values
				}
				if(item.comp_values > max) {
					max = item.comp_values
				}
			})
			var newData = todayData
			var oldData = yesterdayData
			const nameOption = this.$t('sycm.options.flowPanel')
			let chartName = nameOption[this.chartType]
			let newName = this.$t('sycm.common.today')+chartName
			let oldName = this.$t('sycm.common.yesterday')+chartName
			if(this.dateType !== 1) {
				oldName = chartName
				var status = 1
				switch(this.dateType) {
					case 6:
						status = 2
						this.xInterval = 1
					break
					case 7: 
						status = 3
						this.xInterval = 0
					break
					default: this.xInterval = 1
				}
				newName = this.$t('sycm.options.flowChart')[status]+chartName
				newData = yesterdayData
				oldData = todayData
			} else {
				newName = this.$t('sycm.common.today')+chartName
				oldName = this.$t('sycm.common.yesterday')+chartName
			}
			this.chartOption = {
				max: max ? max : 4,
				xData: xData,
				sData: [{
					name: oldName,
					data: oldData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					itemStyle: {
						color: '#2062e6'
					}
				}, {
					name: newName,
					data: newData,
					type: 'line',
					showSymbol: false,
					smooth: true,
					z: 4,
					itemStyle: {
						color: '#f3d024'
					}
				}]
			}
		},
		getChartData(data) {
			const xData = []
			const todayData = []
			const yesterdayData = []
			const d = new Date()
			const hours = d.getHours()
			var max = 0
			data.forEach(item=> {
				if(item.x_values && item.x_values.indexOf('-') < 0 && this.dateType !== 6) {
					xData.push(item.x_values+":00")
					if( hours >= parseInt(item.x_values)) {
						todayData.push(item.values)
					}
				} else {
					xData.push(item.x_values)
					todayData.push(item.values)
				}
				yesterdayData.push(item.comp_values)
				if(item.values > max) {
					max = item.values
				}
				if(item.comp_values > max) {
					max = item.comp_values
				}
			})
			const option = {
				max: max ? max : 4,
				xData: xData,
				sData: [ {
					data: yesterdayData,
					type: 'line',
					smooth: true,
					areaStyle: {},
					itemStyle: {
						color: '#cecece'
					},
					symbol: 'none'
				}, {
					data: todayData,
					type: 'line',
					smooth: true,
					areaStyle: {},
					z: 4,
					itemStyle: {
						color: '#2062e6'
					},
					symbol: 'none'
				}]
			}
			return option
		},
		handleChangeTime(val) {
			this.dateType = val
			let today = new Date()
			switch(this.dateType) {
				case 1:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = Date.now()
					break;
				case 2:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 3:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-7).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 4:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleDatePicker(type) {
			switch(type) {
				case 5:
					this.$refs.dayPicker.focus()
					break;
				case 6:
					this.$refs.weekPicker.focus()
					break;
				case 7:
					this.$refs.monthPicker.focus()
					break;
			}
		},
		changeDatePicker(val,type) {
			this.dateType = type
			const today = new Date(val)
			switch(type) {
				case 5:
					this.monthData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+1).getTime() - 1000
					break;
				case 6:
					this.dayData = ''
					this.monthData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+6).getTime() - 1000
					break;
				case 7:
					this.dayData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth()+1,1).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleCommand(command) {
			this.terminalType = command
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;
		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;
			.title {
				font-size: 16px;
				font-weight: bold;
			}
			.barbox {
				span {
					margin-right: 10px;
				}
			}
		}
		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}
	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}
		.filter-bar {
			display: flex;
			.bar-item {
				margin-left: 10px;
			}
			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;
				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}
				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.sycm-panel {
		display: flex;
		.item {
			flex: 1;
			position: relative;
			overflow: hidden;
			padding: 20px 8px;
			border-left: 1px solid #dbe5ed;
			border-bottom: 1px solid #dbe5ed;
			cursor: pointer;
			.item-main {
				padding: 0 30px;
				.name {
					font-size: 16px;
					line-height: 1;
					margin-bottom: 12px;
				}
			}
			.item-box {
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.li-label {
						color: #999999;
					}
					.li-block {
						font-size: 12px;
						line-height: 1.5;
						color: #999999;
						.number {
							color: #333333;
							font-size: 24px;
							line-height: 30px;
							font-weight: 700;
						}
						&.red {
							color: #fe7c24;
						}

						&.green {
							color: #00ba26;
						}

						[class^=el-icon-] {
							margin-left: 6px;
							font-weight: bold;
						}
					}
				}
			}
			.item-chart {
				height: 64px;
				margin: 20px -8px -20px;
			}
			&:last-child {
				border-right: 1px solid #dbe5ed;
			}
			&.active {
				&:before {
					top: 0;
					left: 0;
					display: block;
					content: "";
					width: 100%;
					height: 4px;
					position: absolute;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.sycm-multiple {
		display: flex;
		flex-flow: wrap;
		margin-bottom: 20px;
		.item {
			width: 20%;
			flex-shrink: 0;
			padding: 20px 8px;
			border: 1px solid #dbe5ed;
			border-top-color: transparent;
			border-left-color: transparent;
			cursor: pointer;
			font-size: 12px;
			line-height: 1.5;
			box-sizing: border-box;
			.item-main {
				.name {
					font-size: 14px;
					.el-popover__reference {
						color: #999999;
						margin-left: 4px;
						cursor: pointer;
					}
				}
				.number {
					font-size: 24px;
					font-weight: 700;
					margin-bottom: 11px;
				}
			}
			.item-box {
				color: #999999;
				max-width: 150px;
				display: flex;
				justify-content: space-between;
				.red {
					color: #fe7c24;
				}

				.green {
					color: #00ba26;
				}

				[class^=el-icon-] {
					margin-left: 6px;
					font-weight: bold;
				}
			}
			&:nth-child(5n - 4) {
				border-left-color: #dbe5ed;
			}
			&:hover {
				border-color: $--sycm-primary;
			}
			&.active {
				border-color: $--sycm-primary;
				outline: 1px solid $--sycm-primary;
			}
		}
	}
	.sycm-chart {
		height: 300px;
	}
}
</style>