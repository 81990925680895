<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'

export default {
    mixins: [resize],
    props: {
        xData: {
            type: Array,
            default: function () {
                return []
            }
        },
        sData: {
            type: Array,
            default: function () {
                return []
            }
        },
        title: {
            type: String,
            default: null
        },
        legendLeft: {
            type: String,
            default: 'auto'
        },
        legendRight: {
            type: String,
            default: 'auto'
        },
        maxAxis: {
            type: String,
            default: '4'
        },
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '200px'
        },
        height: {
            type: String,
            default: '200px'
        },
        xInterval:{
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            chart: null
        }
    },
    mounted() {
        this.initChart()
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById(this.id))

            this.chart.setOption({
                animation: false,
                title: {
                    show: this.title ? true : false,
                    text: this.title,
                     textStyle: {
                        fontSize: 14
                     }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    left: this.legendLeft,
                    right: this.legendRight,
                    itemWidth: 10,
                    itemHeight: 10,
                    icon: 'rect'
                },
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '6%'
                },
                xAxis: {
                    data: this.xData,
                    type: 'category',
                    axisLabel: {
                        interval: this.xInterval,
                        showMaxLabel: true
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    max: this.maxAxis * 1,
                    type: 'value',
                    axisLabel: {
                        inside: true,
						verticalAlign: 'bottom',
                        margin: 0
                    },
                    minInterval: this.maxAxis / 4
                },
                series: this.sData
            })
        }
    }
}
</script>
